import Turbolinks from 'turbolinks';

export default function () {
  var scrollPosition;

  document.addEventListener(
    'turbolinks:load',
    function () {
      if (scrollPosition) {
        window.scrollTo.apply(window, scrollPosition);
        scrollPosition = null;
      }
    },
    false,
  );

  Turbolinks.reload = function () {
    scrollPosition = [window.scrollX, window.scrollY];
    Turbolinks.visit(window.location, {action: 'replace'});
  };
}
