/**
 * This contains DOM-level drivers to add interactivity to otherwise stale components.
 */

import loadjs from 'loadjs';

const CLIPBOARD_JS =
  'https://cdnjs.cloudflare.com/ajax/libs/clipboard.js/1.7.1/clipboard.min.js';
const SIMPLE_MDE_JS =
  'https://cdn.jsdelivr.net/simplemde/latest/simplemde.min.js';
const JSON_EDITOR_JS =
  'https://cdnjs.cloudflare.com/ajax/libs/jsoneditor/9.1.4/jsoneditor.min.js';
const JSON_EDITOR_CSS =
  'https://cdnjs.cloudflare.com/ajax/libs/jsoneditor/9.1.4/jsoneditor.css';

function initializeJSONEditor(textAreaNode) {
  if (textAreaNode.dataset.ujsInitialized) {
    return;
  }

  const editorNode = document.createElement('div');
  const height = textAreaNode.dataset.height;
  if (height) {
    editorNode.style.height = `${parseInt(height, 10)}px`;
  } else {
    editorNode.style.height = '400px';
  }
  textAreaNode.insertAdjacentElement('afterend', editorNode);
  textAreaNode.dataset.ujsInitialized = true;

  // Configure the JSON editor to control the textarea.
  const editor = new JSONEditor(editorNode, {
    mode: 'code',
    limitDragging: true,
    onChangeText: (jsonText: string) => {
      textAreaNode.value = jsonText;
    },
  });
  editor.set(JSON.parse(textAreaNode.value));
}

function initializeDriver(
  name: string,
  resources: Array<string>,
  initializeNode,
) {
  const nodes = document.querySelectorAll(`[data-ujs="${name}"]`);
  if (nodes.length === 0) {
    return;
  }
  if (loadjs.isDefined(name)) {
    nodes.forEach(initializeNode);
    return;
  }
  if (resources.length > 0) {
    loadjs(resources, name, () => {
      nodes.forEach(initializeNode);
    });
  } else {
    nodes.forEach(initializeNode);
  }
}

document.addEventListener('turbolinks:load', function () {
  initializeDriver('clipboard', [CLIPBOARD_JS], node => {
    const clipboard = new Clipboard(node);
    const message =
      node.dataset.clipboardSuccessMessage || 'Copied to clipboard!';
    clipboard.on('success', _ => {
      sweetAlert.fire({
        type: 'info',
        toast: true,
        text: message,
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: true,
        timer: 2000,
        timerProgressBar: true,
        position: 'top-end',
      });
    });
  });
  initializeDriver('file', [], node => {
    const fileInput = node.querySelector('input[type=file]');
    fileInput.onchange = () => {
      if (fileInput.files.length > 0) {
        const fileName = node.closest('div').querySelector('.file-name');
        fileName.textContent = fileInput.files[0].name;
      }
    };
  });
  initializeDriver(
    'json-editor',
    [JSON_EDITOR_CSS, JSON_EDITOR_JS],
    initializeJSONEditor,
  );
  initializeDriver('markdown-editor', [SIMPLE_MDE_JS], node => {
    new SimpleMDE({
      element: node,
      spellChecker: false,
    });
  });
  document.querySelectorAll('.is-hidden-until-js').forEach(node => {
    node.classList.remove('is-hidden-until-js');
  });
});
