// Service worker
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('/service-worker.js')
//     .then(function(registration) {
//       // console.log('Registration successful, scope is:', registration.scope);
//     })
//     .catch(function(error) {
//       // console.log('Service worker registration failed, error:', error);
//     });
// }

// window.addEventListener('beforeinstallprompt', event => {
//   // console.log('👍', 'beforeinstallprompt', event);
//   // Stash the event so it can be triggered later.
//   window.deferredPrompt = event;
//   // Remove the 'hidden' class from the install button container
//   // divInstall.classList.toggle('hidden', false);
// });
