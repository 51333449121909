import 'regenerator-runtime/runtime';
import '@cable_ready/polyfills';

import * as Routes from './lib/routes.js.erb';
import Turbolinks from 'turbolinks';
import LocalTime from 'local-time';
import Rails from '@rails/ujs';
import TurbolinksRefresh from './util/TurbolinksRefresh';
import RateLimiter from './util/RateLimiter';
import Breadcrumbs from './util/Breadcrumbs';

window.Routes = Routes;

Turbolinks.start();
TurbolinksRefresh();
RateLimiter();
Breadcrumbs();

LocalTime.start();
if (!window._rails_loaded) {
  Rails.start();
}

window.sweetAlert = Swal.mixin({
  allowOutsideClick: true,
  showClass: {
    popup: 'swal2-show',
    backdrop: 'swal2-backdrop-show',
    icon: 'swal2-icon-show',
  },
  hideClass: {
    popup: 'swal2-hide',
    backdrop: 'swal2-backdrop-hide',
    icon: 'swal2-icon-hide',
  },
  customClass: {
    container: 'sapco-modal',
    confirmButton: 'button is-outlined is-primary',
    cancelButton: 'button is-outlined is-danger',
  },
  showCancelButton: true,
  buttonsStyling: false,
});

require('./actions/ui_drivers');
require('./actions/service_worker');
