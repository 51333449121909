/**
 * Track the last 10 pages the user has been to.
 * We pass this up during a sign up.
 */

const MAX_BREADCRUMB_SIZE = 10;
const STORAGE_KEY = 'breadcrumbs/v1';

export default function () {
  document.addEventListener(
    'turbolinks:load',
    function () {
      let currentBreadcrumbs = JSON.parse(
        localStorage.getItem(STORAGE_KEY) || '[]',
      );

      currentBreadcrumbs.push(window.location.pathname);
      if (currentBreadcrumbs.length > MAX_BREADCRUMB_SIZE) {
        currentBreadcrumbs = currentBreadcrumbs.slice(-MAX_BREADCRUMB_SIZE);
      }

      localStorage.setItem(STORAGE_KEY, JSON.stringify(currentBreadcrumbs));

      /* develblock:start */
      console.debug('Breadcrumbs: %s', currentBreadcrumbs.join(', '));
      /* develblock:end */

      // The sign up form uses this.
      const breadcrumbNode = document.querySelector(
        'input#breadcrumbs',
      ) as HTMLInputElement | null;
      if (breadcrumbNode) {
        breadcrumbNode.value = currentBreadcrumbs.join(', ');
      }
    },
    false,
  );
}
