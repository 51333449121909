// @flow

export default function () {
  document.addEventListener(
    'turbolinks:load',
    function () {
      const isRateLimited = document.querySelector(
        'meta[name="is-rate-limited"]',
      );

      if (gon?.current_user?.is_confirmed == false || isRateLimited) {
        // We handle this separately by blocking everything.
        return;
      }

      const controllerBlacklist = [
        'ecomm_analyze/main',
        'ecomm_analyze/users',
        'ecomm_analyze/rate_limiter',
        'ecomm_analyze/saved_searches',
        'static',
        'users/confirmations',
        'users/passwords',
        'users/registrations',
        'users/sessions',
      ];
      const isOnBlacklist = controllerBlacklist.some(
        (controllerName: string) => {
          return document.body?.classList.contains(controllerName);
        },
      );

      if (isOnBlacklist) {
        /* develblock:start */
        console.debug('No trigger bump on blacklist...');
        /* develblock:end */
        return;
      }
      /* develblock:start */
      console.debug('Trigger bump...');
      /* develblock:end */
      $.ajax({
        url: Routes.ecomm_analyze_ping_path(),
      });
    },
    false,
  );
}
